<template>
    <div>
        <b-table responsive bordered hover show-empty :busy="false" :items="refunds.data" :fields="fields" class="mb-3">
            <template #empty="scope"> Data tidak ditemukan </template>
            <template v-slot:cell(no_invoice)="row">
                <div>
                    <span>{{ row.item.no_invoice }}</span>
                    <small class="d-block">
                        <b>{{ row.item.tanggal | moment("DD-MMM-YYYY") }}</b>
                    </small>
                </div>

            </template>
            <template v-slot:cell(supplier)="row">
                <div>
                    <span class="d-block">{{ row.item.shop_name }}</span>
                    <small>{{ row.item.supplier.nama }}</small>
                </div>
            </template>
            <template v-slot:cell(total_dana)="row">
                <div class="d-flex justify-content-between">
                    <span>Rp</span>
                    <span>{{ $helper.rupiah(row.item.total_dana) }}</span>
                </div>
            </template>
            <template v-slot:cell(status)="row">
                <template v-if="row.item.status === 1">
                    <div class="badge badge-primary">
                        proses
                    </div>
                </template>
                <template v-if="row.item.status === 2">
                    <div class="badge badge-warning">
                        menunggu approve
                    </div>
                </template>
                <template v-if="row.item.status === 3">
                    <div class="badge badge-success">
                        approved
                    </div>
                </template>
            </template>
            <template v-slot:cell(detail)="row">
                <b-button @click="handleDetail(row.item)" size="sm" variant="pgiBtn">
                    <i class="fa fa-eye"></i>
                    Detail
                </b-button>
            </template>
        </b-table>
        <div class="pagination-wrapper d-flex align-items-center justify-content-end">
            <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
                @input="onPageChange"></b-pagination>
        </div>
        <b-modal id="m-detail" size="lg" class="modal-lg" title="Detail Refund" no-close-on-backdrop no-close-on-esc
            hide-footer>
            <ul v-for="item in details.detail_refund" :key="item.id" class="list-group list-group-flush">
                <li class="list-group-item p-1">
                    <div class="row">
                        <div class="col-md-8">
                            <div>{{ item.nama_barang }}</div>
                            <small>Rp {{ $helper.rupiah(item.harga) }} x {{ item.total_item }} {{ item.satuan }} </small>
                        </div>
                        <div class="col-md-4 d-flex justify-content-end">
                            <h6 class="text-end">Rp {{ $helper.rupiah(item.harga * item.total_item) }}</h6>
                        </div>
                    </div>
                </li>
            </ul>
        </b-modal>
    </div>
</template>


<script>
export default {
    data() {
        return {
            details: [],
            fields: [
                {
                    key: "no_invoice",
                    label: "Invoice",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: false,
                },
                {
                    key: "total_dana",
                    label: "Total Refund",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: false,
                },
                {
                    key: "supplier",
                    label: "Supplier",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: false,
                },
                {
                    key: "total_item",
                    label: "Jumlah",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: false,
                },
                {
                    key: "detail",
                    label: "Detail",
                    thClass: "nameOfTheClassTh",
                    tdClass: "nameOfTheClassTd",
                    sortable: false,
                }
            ],
            currentPage: 1,
            perPage: null,
            rows: null,
            page: '',
            size: '',
            from: '',
            limit: '',
            query: "",
        }
    },
    computed: {
        refunds() {
            return this.$store.state.refund.refund_history
        }
    },
    methods: {
        handleDetail(value) {
            this.details = value;
            this.$bvModal.show('m-detail')
            console.log(value)
        },
        onPageChange() { }
    }
}
</script>