<template>
    <div class="animated fadeIn">
        <b-card>
            <template v-slot:header>
                <h5>Riwayat Dana Refund</h5>
            </template>
            <div class="mb-3">
                <div class="row">
                    <!-- <div class="col-md-6 col-lg-3">
                        <b-form-group label="Filter Merchant">
                            <multiselect :options="merchants" :internal-search="true" :allow-empty="false" deselect-label=""
                                select-label="" label="nama_merchant" track-by="id" placeholder="pilih supplier" />
                        </b-form-group>
                    </div> -->
                    <div class="col-md-6 col-lg-4">
                        <b-form-group label="Filter Tanggal">
                            <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true"
                                :showDropdowns="true" :autoApply="true" @select="handleSelectDate">
                            </date-range-picker>
                        </b-form-group>
                    </div>
                    <!-- <div class="col-md-6 col-lg-3">
                        <b-form-group label="Filter Status">
                            <multiselect :options="optionsStatus" :internal-search="true" :allow-empty="false"
                                deselect-label="" select-label="" label="name" track-by="id" placeholder="pilih supplier" />
                        </b-form-group>
                    </div> -->
                </div>
            </div>
            <TableRefundDana />
        </b-card>
    </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import TableRefundDana from '../../components/refund_dana/history/TableRefundDana.vue'
export default {
    components: { DateRangePicker, TableRefundDana },
    data() {
        return {
            formRangeDate: {
                from: null,
                to: null
            },
            dateRange: { startDate: null, endDate: null },
            optionsStatus: [
                {
                    id: 1,
                    name: "Pending"
                },
                {
                    id: 2,
                    name: "Success"
                },
                {
                    id: 3,
                    name: "Reject"
                },
            ],
        };
    },
    methods: {
        handleSelectDate(value) {
            const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
            const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
            this.filter.from = startDate
            this.filter.to = endDate
        },
    },
    mounted() {
        this.$store.dispatch("getHistoryRefund")
    }
}
</script>